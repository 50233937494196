import "aos/dist/aos.css";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./layout/Footer";
import Home from "./pages/home/Home";
import Header from "./layout/Header";
import Capabilities from "./pages/capabilities/Capabilities";
import AboutUs from "./pages/about-us/AboutUs";
import Services from "./pages/services/Services";
import ContactUs from "./pages/contactus/ContactUs";
import ScrollToTop from "./ScrollToTop";
import { NotFound } from "./pages/NotFound";

function App() {
  return (
    <div className="wrapper">
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/capabilities" element={<Capabilities />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
