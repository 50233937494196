import React from "react";
import bannerImage from "../../../assets/images/banner-kv.png";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  const goToServicesPage = () => {
    navigate("/services");
  };

  return (
    <div className="alpha-home">
      <div className="banner-grp">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="2300"
            >
              <div className="banner-cont">
                <h5>Alpha</h5>
                <h1>Your partner in engineering excellence</h1>
                <p>
                  Empowering your business with world-class engineering
                  solutions tailored to meet your unique challenges. Trust Alpha
                  DeServe for reliable, innovative, and cost-effective
                  engineering services.
                </p>
                <div className="mt-4">
                  <PrimaryButton
                    buttonName={"Explore"}
                    type={"button"}
                    onClick={goToServicesPage}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="2300"
            >
              <div className="text-center banner-kv">
                <figure>
                  <img src={bannerImage} alt="Banner-kv" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
