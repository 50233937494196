import React from "react";
import Expertise from "../../../components/Expertise";
import { EngineeringList } from "../../../const/expertiseEngineering";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";

const ExpertiseEngineering = () => {
  const navigate = useNavigate();

  const goToServicesPage = () => {
    navigate("/services");
  };
  return (
    <section>
      <div className="container">
        <div
          className="text-center"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
          data-aos-delay="100"
        >
          <h2>Expertise in engineering</h2>
        </div>
        <div className="">
          <Expertise data={EngineeringList} />
        </div>
        <div
          className="mt-4 text-center"
          data-aos="fade-up"
          data-aos-duration="2100"
          data-aos-delay="60"
        >
          <PrimaryButton
            buttonName={"Learn more"}
            type={"button"}
            onClick={goToServicesPage}
          />
        </div>
      </div>
    </section>
  );
};

export default ExpertiseEngineering;
