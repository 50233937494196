import React from "react";
import connectImg from "../../../assets/images/home/contact-us.png";
import location from "../../../assets/images/location.png";
import phone from "../../../assets/images/phone.png";
import mail from "../../../assets/images/mail.png";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";

const ConnectWithUs = () => {
  const navigate = useNavigate();

  const goToContactPage = () => {
    navigate("/contact-us");
  };
  return (
    <section className="pt-0 container connectWithUs">
      <div className="row">
        <div
          className="col-lg-6 contactImg"
          data-aos="fade-up"
          data-aos-duration="2500"
          data-aos-delay="50"
        >
          <figure className="mb-0">
            <img src={connectImg} alt="connectWithUs" className="img-fluid" />
          </figure>
        </div>
        <div className="col-lg-6 rightSection ">
          <h2
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2500"
            data-aos-delay="50"
          >
            Connect with us
          </h2>
          <div
            className="contectDetails"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2500"
          >
            <p className="location">
              No.2/88, 1st Floor, Saravana Matrix Tower LLP, OMR, Seevaram,
              Chennai
              <br />
              PIN - 600096.
            </p>
            <figure className="icon">
              <img src={location} alt="location" className="img-fluid" />
            </figure>
          </div>
          <div
            className="contectDetails"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="2500"
          >
            <a href="tel:+919962118160">+91 - 99621 18160</a>
            <figure className="icon">
              <img src={phone} alt="phone" className="img-fluid" />
            </figure>
          </div>
          <div
            className="contectDetails"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="2500"
          >
            <a href="mailto:info@alphadeserve.com">info@alphadeserve.com</a>
            <figure className="icon">
              <img src={mail} alt="mail" className="img-fluid" />
            </figure>
          </div>
          <div
            className="mt-4"
            data-aos="fade-up"
            data-aos-duration="2500"
            data-aos-delay="80"
          >
            <PrimaryButton
              buttonName={"Contact Us"}
              type={"button"}
              onClick={goToContactPage}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectWithUs;
