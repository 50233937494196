import React from "react";
import location from "../../../assets/images/location.png";
import phone from "../../../assets/images/phone.png";
import mail from "../../../assets/images/mail.png";

const ContactInfo = () => {
  return (
    <section className="connectWithUs">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-duration="2500"
            data-aos-delay="50"
          >
            <div className="g-map">
              <iframe
                width="100%"
                height="100%"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d972.0699176380787!2d80.2415432!3d12.9539476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267568aef8cc1%3A0x1182973a7118f74d!2sAlpha%20HR%20Services%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1723537246485!5m2!1sen!2sin"
                allowFullScreen
                title="Google Maps Location"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 rightSection">
            <h2
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2300"
              data-aos-delay="50"
            >
              Connect with us
            </h2>
            <div
              className="contectDetails"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2100"
            >
              <p className="location">
                No.2/88, 1st Floor, Saravana Matrix Tower LLP, OMR, Seevaram,
                Chennai
                <br />
                PIN - 600096.
              </p>
              <figure className="icon">
                <img src={location} alt="location" className="img-fluid" />
              </figure>
            </div>
            <div
              className="contectDetails"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2200"
            >
              <a href="tel:+919962118160">+91 - 99621 18160</a>
              <figure className="icon">
                <img src={phone} alt="phone" className="img-fluid" />
              </figure>
            </div>
            <div
              className="contectDetails"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2300"
            >
              <a href="mailto:info@alphadeserve.com">
                info@alphadeserve.com
              </a>
              <figure className="icon">
                <img src={mail} alt="mail" className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
