import React from "react";
import detailsImg from "../../../assets/images/aboutUs/innovative-features.png";
import Collaboration from "../../../assets/images/aboutUs/Collaboration & Transparency.png";
import CuttingEdge from "../../../assets/images/aboutUs/Cutting-Edge Technology.png";
import Customized from "../../../assets/images/aboutUs/Customized Solutions.png";
import Sustainable from "../../../assets/images/aboutUs/Sustainable Practices.png";

export const Innovative = () => {
  return (
    <section className="innovative-sec">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2500"
            data-aos-delay="100"
          >
            <h2
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="2500"
              data-aos-delay="100"
            >
              Innovative features
            </h2>
            <div className="container our-goals">
              <div className="separate-goals">
                <div
                  className="our-goalsImg"
                  data-aos="zoom-in"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1700"
                >
                  <img src={CuttingEdge} alt="CuttingEdge" />
                </div>
                <div>
                  <p className="our">Cutting-Edge Technology</p>
                  <p>
                    We stay ahead of the curve by integrating the latest
                    technologies into our engineering processes, ensuring our
                    solutions are efficient, reliable, and scalable
                  </p>
                </div>
              </div>
              <div className="separate-goals">
                <div
                  className="our-goalsImg"
                  data-aos="zoom-in"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1700"
                >
                  <img src={Sustainable} alt="Sustainable" />
                </div>
                <div>
                  <p className="our">Sustainable Practices</p>
                  <p>
                    Our commitment to sustainability drives us to design and
                    implement eco-friendly solutions that minimize environmental
                    impact while maximizing performance.
                  </p>
                </div>
              </div>
              <div className="separate-goals">
                <div
                  className="our-goalsImg"
                  data-aos="zoom-in"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="1700"
                >
                  <img src={Customized} alt="Customized" />
                </div>
                <div>
                  <p className="our">Customized Solutions</p>
                  <p>
                    We understand that no two projects are the same. That’s why
                    we tailor our services to meet the unique requirements of
                    each client, ensuring optimal results.
                  </p>
                </div>
              </div>
              <div className="separate-goals">
                <div className="our-goalsImg">
                  <img src={Collaboration} alt="Collaboration & Transparency" />
                </div>
                <div>
                  <p className="our">Collaboration & Transparency</p>
                  <p>
                    We believe in working closely with our clients, fostering a
                    collaborative environment that promotes transparency, trust,
                    and mutual growth
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 text-center"
            data-aos="fade-left"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2500"
            data-aos-delay="100"
          >
            <figure className="m-0">
              <img src={detailsImg} alt="about" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};
