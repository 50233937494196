import React from "react";
import ContactInfo from "./components/ContactInfo";
import GetInTouch from "./components/GetInTouch";
import { InnerBanner } from "../../components/InnerBanner";
import bannerImg from "../../assets/images/contactUs/contactus-banner.png";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>{`Contact Us | Alpha De Serve`}</title>
      </Helmet>
      <InnerBanner
        title={<>Reach out and let's make progress happen</>}
        // subtitle={"Contact Us"}
        banner_kv={bannerImg}
      />
      <ContactInfo />
      <GetInTouch />
    </div>
  );
};

export default ContactUs;
