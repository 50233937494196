import arrow from "../assets/images/arrow.png";
const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <figure className="icon m-0">
        <img src={arrow} alt="Arrow Prev" className="img-fluid" />
      </figure>
    </div>
  );
};

export default CustomPrevArrow;
