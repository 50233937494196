import React from "react";
export const InnerBanner = ({ title, subtitle, banner_kv, description }) => {
  return (
    <div className="alpha-home inner-banner">
      <div className="banner-grp">
        <div className="container">
          <div className="row banner-content">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="2300"
            >
              <div className="banner-count">
                {/* <h2>{subtitle}</h2> */}
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="2300"
            >
              <div className="text-center banner-kv">
                <figure>
                  <img src={banner_kv} alt="inner-Banner-kv" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
