import React from "react";

const Card = ({ number, text, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`slider-card ${isActive ? "active" : ""}`}
    >
      <h2>{number}</h2>
      <p>{text}</p>
    </div>
  );
};

export default Card;
