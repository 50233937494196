import React from "react";
import Slider from "react-slick";
import { clientList } from "../../../const/clientList";
import arrow from "../../../assets/images/arrow.png";
import CustomPrevArrow from "../../../components/SliderPrevArrow";

const ClientList = () => {
  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <figure className="icon">
          <img src={arrow} alt="Arrow Prev" className="img-fluid" />
        </figure>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: true,
          infinite: true,
        },
      },
    ],
  };

  return (
    <section className="container clientList">
      <div
        className="text-center"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2500"
        data-aos-delay="100"
      >
        <h2>Top clients</h2>
      </div>
      <div className="slider">
        <Slider {...settings}>
          {clientList.map((list, index) => {
            return (
              <figure className="icon" data-aos="fade-up" data-aos-duration="2000"
                data-aos-delay="70" key={index}>
                <img src={list?.logo} alt={list?.name} className="img-fluid" />
              </figure>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default ClientList;
