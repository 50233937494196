import React from "react";
import NotFountImg from "../assets/images/404.png";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const NotFound = () => {
  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/");
  };
  return (
    <section className="notfound-sec">
      <Helmet>
        <title>{`Page not found | Alpha De Serve`}</title>
      </Helmet>
      <div className="container">
        <div className="notFound">
          <figure>
            <img src={NotFountImg} alt="pageNotFound" />
          </figure>
          <div className="notfoundBack">
            <PrimaryButton
              buttonName={"Back To Home"}
              type={"button"}
              onClick={backToHome}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
