import React from "react";
import TalentServiceImage from "../../../assets/images/Service/talent_services.png";
import sampleIcon from "../../../assets/images/sample-icon.png";
import { included_services } from "../../../const/servicelist";

const TalentServices = () => {
  return (
    <section className="p-0">
      <div className="container">
        <div className="talent-section">
          <div className="title-content" data-aos="fade-up">
            <h2>Talent services</h2>
            <p className="mb-5">
              Alpha De Serve also excels in talent management, connecting
              businesses with top-tier professionals who can drive growth and
              innovation. Whether you need contract workers, permanent hires, or
              project-based specialists, we’ve got you covered.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <figure
                className="talent-image"
                data-aos="fade-right"
                data-aos-duration="2300"
              >
                <img
                  src={TalentServiceImage}
                  alt="talentService"
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="col-lg-6">
              <div className="service-list-container" data-aos="fade-left">
                <h3>Our services include</h3>
                <ul className="service-list">
                  {included_services.map((service, index) => (
                    <li key={index} className="service-item">
                      {/* <img
                        src={sampleIcon}
                        alt={`Service ${index + 1}`}
                        className="service-icon"
                        data-aos="zoom-in"
                        data-aos-duration="2500"
                      /> */}
                      <p className="service-text">{service.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TalentServices;
