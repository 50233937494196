import React, { useState, useEffect } from "react";

const Products = ({ customClass, data }) => {
  const [activeItem, setActiveItem] = useState(data[0]);

  const handleClick = (item) => {
    setActiveItem(item);
  };

  useEffect(() => {
    if (data.length > 0) {
      setActiveItem(data[0]);
    }
  }, [data]);

  return (
    <div className="expertise-group">
      <div className={`row ${customClass}`}>
        <div
          className="col-lg-4"
          data-aos="fade-right"
          data-aos-duration="2500"
          data-aos-delay="100"
        >
          <div className="expertise-list">
            <ul>
              {data.map((item) => (
                <li
                  key={item.id}
                  onClick={() => handleClick(item)}
                  className={`expertise-item  ${activeItem?.id === item.id ? "active-card" : ""
                    }`}
                >
                  {item?.defaultIcon || item?.activeIcon ? (
                    <div>
                      <img
                        src={
                          activeItem?.id === item.id
                            ? item?.activeIcon
                            : item?.defaultIcon
                        }
                        alt=""
                        className=""
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <p>{item?.title}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-lg-4 experties-bg">
          <div className="experties pt-0">
            <div
              className="text-center"
              data-aos="fade-in"
              data-aos-duration="3000"
              data-aos-delay="100"
            >
              {activeItem && <img src={activeItem.thumbnail} alt="Thumbnail" />}
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 expertise-cont"
          data-aos="fade-left"
          data-aos-duration="2500"
          data-aos-delay="100"
        >
          <h3>{activeItem?.title}</h3>
          <p>{activeItem?.para}</p>

          {/* <ul>
            {activeItem?.content?.map((selectItem, index) => (
              <li className="expertise-item expert-list" key={index}>
                {selectItem?.keypoints}
              </li>
            ))}
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default Products;
