import React from "react";
import bannerImg from "../../assets/images/aboutUs/aboutus-banner.png";
import { InnerBanner } from "../../components/InnerBanner";
import { DetailAbout } from "./component/DetailAbout";
import { Innovative } from "./component/Innovative";
import { OurService } from "./component/OurService";
import { OurTeam } from "./component/OurTeam";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>{`About Us | Alpha De Serve`}</title>
      </Helmet>
      <InnerBanner
        title={
          <>
            Our journey of <br />
            engineering mastery
          </>
        }
        // subtitle={"About Us"}
        description={
          "At Alpha DeServe, we’re committed to redefining engineering standards through innovative solutions that drive progress and success for our clients"
        }
        banner_kv={bannerImg}
      />
      <DetailAbout />
      <Innovative />
      <OurService />
      {/* <OurTeam /> */}
    </>
  );
};

export default AboutUs;
