import Automotive from "../assets/images/Automotive.png";
import HeavyEngineering from "../assets/images/HeavyEngineering.png";
import Energy from "../assets/images/Energy.png";
import MedicalEquipment from "../assets/images/MedicalEquipment.png";
import Aerospace from "../assets/images/Aerospace.png";

export const industriescardList = [
  {
    id: 1,
    tittle: "Automotive",
    para: "We drive innovation in automotive engineering, delivering solutions that enhance vehicle performance, safety, and sustainability, from powertrain systems to interior components.",
    thumbnail: Automotive,
  },
  {
    id: 2,
    tittle: "Heavy engineering",
    para: "Our expertise in heavy engineering supports large-scale industrial projects, providing robust structural design, process optimization, and automation for machinery and infrastructure.",
    thumbnail: HeavyEngineering,
  },
  {
    id: 3,
    tittle: "Energy",
    para: "We power the energy sector with advanced design, analysis, and optimization services for both renewable and non-renewable systems, focusing on sustainability and reliability.",
    thumbnail: Energy,
  },
  {
    id: 4,
    tittle: "Medical equipment",
    para: "We deliver precision engineering for the medical equipment industry, ensuring that products meet strict safety standards and perform reliably in critical healthcare settings.",
    thumbnail: MedicalEquipment,
  },
  {
    id: 2,
    tittle: "Aerospace",
    para: "Our aerospace solutions ensure precision and innovation in component design, systems integration, and validation, meeting the high demands of this mission-critical industry.",
    thumbnail: Aerospace,
  },
];
