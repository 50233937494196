import coupa from "../assets/clientLogo/coupa.png";
import cresoft from "../assets/clientLogo/cresoft.png";
import tvs from "../assets/clientLogo/tvs.png";
import royal from "../assets/clientLogo/re.png";
import daimler from "../assets/clientLogo/daimler-logo.png";
import cyient from "../assets/clientLogo/cyient.png";
import murugappa from "../assets/clientLogo/murugappa.png";
import tubeInvestments from "../assets/clientLogo/tube-investments.png";
import LTIndia from "../assets/clientLogo/L&T.png";
import ForceMotors from "../assets/clientLogo/force_motors.png";
import JNJ from "../assets/clientLogo/j&j.png";

export const clientList = [
  {
    name: "",
    logo: coupa,
  },
  {
    name: "",
    logo: daimler,
  },
  {
    name: "",
    logo: cresoft,
  },
  {
    name: "",
    logo: cyient,
  },
  {
    name: "",
    logo: tvs,
  },
  {
    name: "",
    logo: royal,
  },
  {
    name: "",
    logo: JNJ,
  },

  {
    name: "",
    logo: murugappa,
  },
  {
    name: "",
    logo: tubeInvestments,
  },
  {
    name: "",
    logo: LTIndia,
  },
  {
    name: "",
    logo: ForceMotors,
  },
];
