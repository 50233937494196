import productdesignImg from "../../src/assets/images/capabilities/product-design.png";
import manufacturingEngineeringImg from "../../src/assets/images/capabilities/manufacturing-engineering.png";
import automationImg from "../../src/assets/images/capabilities/automation.png";
import designValidationImg from "../../src/assets/images/capabilities/design-validation-&-virtual-simulation.png";
import reverseEnginneringImg from "../../src/assets/images/capabilities/reverse-engineering-&-VAVE.png";
import dataMigrationImg from "../../src/assets/images/capabilities/data-migration-&-tech-publication.png";

export const ProductList = [
  {
    id: 1,
    title: "Product Design",
    para: " We bring ideas to life with cutting-edge product design services, from initial concept development to detailed engineering. Our multidisciplinary team ensures that your products are not only functional and aesthetically pleasing but also manufacturable and market-ready.",
    thumbnail: productdesignImg,
    // content: [
    //   {
    //     keypoints: "Concept Development",
    //   },
    //   {
    //     keypoints: "Styling Feedback",
    //   },
    //   {
    //     keypoints: "Functional design review",
    //   },
    //   {
    //     keypoints: "Design Development",
    //   },
    //   {
    //     keypoints: "Interior Trims (BIW)",
    //   },
    // ],
  },
  {
    id: 2,
    title: "Manufacturing Engineering",
    para: "Optimize production processes with our manufacturing engineering services. We focus on improving efficiency, reducing costs, and ensuring quality, enabling you to achieve the best possible outcomes for your manufacturing operations.",
    thumbnail: manufacturingEngineeringImg,
    // content: [
    //   {
    //     keypoints: "Concept Development",
    //   },
    //   {
    //     keypoints: "Styling Feedback",
    //   },
    //   {
    //     keypoints: "Functional design review",
    //   },
    //   {
    //     keypoints: "Design Development",
    //   },
    //   {
    //     keypoints: "Interior Trims (BIW)",
    //   },
    // ],
  },
  {
    id: 3,
    title: "Automation",
    para: "Enhance productivity and reduce operational costs with our advanced automation solutions. We design and implement automation systems that streamline processes, improve accuracy, and minimize human error across various stages of production.",
    thumbnail: automationImg,
    // content: [
    //   {
    //     keypoints: "Concept Development",
    //   },
    //   {
    //     keypoints: "Styling Feedback",
    //   },
    //   {
    //     keypoints: "Functional design review",
    //   },
    //   {
    //     keypoints: "Design Development",
    //   },
    //   {
    //     keypoints: "Interior Trims (BIW)",
    //   },
    // ],
  },
  {
    id: 4,
    title: "Design Validation & Virtual Simulation",
    para: "Validate your designs before they go into production using our sophisticated virtual simulation tools. We perform thorough testing and analysis to identify potential issues early, reducing risk and ensuring product reliability.",
    thumbnail: designValidationImg,
    // content: [
    //   {
    //     keypoints: "Concept Development",
    //   },
    //   {
    //     keypoints: "Styling Feedback",
    //   },
    //   {
    //     keypoints: "Functional design review",
    //   },
    //   {
    //     keypoints: "Design Development",
    //   },
    //   {
    //     keypoints: "Interior Trims (BIW)",
    //   },
    // ],
  },
  {
    id: 5,
    title: "Reverse Engineering & VAVE",
    para: "Unlock new value from existing products with our reverse engineering and Value Analysis/Value Engineering (VAVE) services. We analyze your products to enhance functionality, reduce costs, and improve performance, extending product life cycles.",
    thumbnail: reverseEnginneringImg,
    // content: [
    //   {
    //     keypoints: "Concept Development",
    //   },
    //   {
    //     keypoints: "Styling Feedback",
    //   },
    //   {
    //     keypoints: "Functional design review",
    //   },
    //   {
    //     keypoints: "Design Development",
    //   },
    //   {
    //     keypoints: "Interior Trims (BIW)",
    //   },
    // ],
  },
  {
    id: 6,
    title: "Data Migration & Tech. Publication",
    para: "Seamlessly migrate and manage data across platforms with our data migration services. Additionally, our technical publication services ensure that all your product documentation is clear, accurate, and compliant with industry standards.",
    thumbnail: dataMigrationImg,
    // content: [
    //   {
    //     keypoints: "Concept Development",
    //   },
    //   {
    //     keypoints: "Styling Feedback",
    //   },
    //   {
    //     keypoints: "Functional design review",
    //   },
    //   {
    //     keypoints: "Design Development",
    //   },
    //   {
    //     keypoints: "Interior Trims (BIW)",
    //   },
    // ],
  },
];
