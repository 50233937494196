import React, { useState } from "react";
import connectImg from "../../../assets/images/contactUs/get-in-touch.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PrimaryButton from "../../../components/PrimaryButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GetInTouch = () => {
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    contactNumber: yup
      .string()
      .required("Contact number is required")
      .matches(/^[0-9]+$/, "Contact number must be only digits"),
    email: yup
      .string()
      .required("Email address is required")
      .email("Invalid email address"),
    message: yup.string().required("Message is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://alphadeserve.com/php/send-email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data),
        }
      );
      console.log(response, "response");
      const result = await response.json();
      console.log("Response result", result);
      if (response.ok) {
        setIsLoading(false);
        toast.success("Form submitted successfully!");
        reset(); // Reset the form after successful submission
      } else {
        setIsLoading(false);
        toast.error("Failed to send message.");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error occurred. Please try again.");
    }
  };

  return (
    <section className="pt-0">
      <div className="container">
        <div
          className="text-center"
          data-aos="fade-up"
          data-aos-duration="2100"
          data-aos-delay="100"
        >
          <h2>Get in touch</h2>
        </div>
        <div className="row get-touch-row">
          <div
            className="col-lg-6"
            data-aos="fade-right"
            data-aos-duration="2100"
            data-aos-delay="100"
          >
            <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
              <div className="field-item">
                <input
                  type="text"
                  className={`field-control ${
                    errors.firstName ? "has-error" : ""
                  }`}
                  placeholder="First name*"
                  {...register("firstName")}
                />
                <p className="error-message">{errors.firstName?.message}</p>
              </div>
              <div className="field-item">
                <input
                  type="text"
                  className={`field-control ${
                    errors.lastName ? "has-error" : ""
                  }`}
                  placeholder="Last name*"
                  {...register("lastName")}
                />
                <p className="error-message">{errors.lastName?.message}</p>
              </div>
              <div className="field-item">
                <input
                  type="number"
                  className={`field-control ${
                    errors.contactNumber ? "has-error" : ""
                  }`}
                  placeholder="Contact number*"
                  {...register("contactNumber")}
                />
                <p className="error-message">{errors.contactNumber?.message}</p>
              </div>
              <div className="field-item">
                <input
                  type="text"
                  className={`field-control ${errors.email ? "has-error" : ""}`}
                  placeholder="Email address*"
                  {...register("email")}
                />
                <p className="error-message">{errors.email?.message}</p>
              </div>
              <div className="field-item">
                <textarea
                  className={`field-control ${
                    errors.message ? "has-error" : ""
                  }`}
                  placeholder="Your message*"
                  {...register("message")}
                />
                <p className="error-message">{errors.message?.message}</p>
              </div>
              <div className="mt-2">
                {isLoading ? (
                  <div className="button-loader">
                    <PrimaryButton
                      buttonName={
                        <>
                          <span className="spinner"></span>
                        </>
                      }
                      type={"button"}
                    />
                  </div>
                ) : (
                  <PrimaryButton buttonName={"Submit"} type={"submit"} />
                )}
              </div>
            </form>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="100"
          >
            <figure className="mb-0 contact-img">
              <img src={connectImg} alt="connectWithUs" className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default GetInTouch;
