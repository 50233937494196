import prodDesign from "../assets/images/Gradient/product-design.png";
import prodDesignWhite from "../assets/images/White/product-design-white.png";
import manufacturing from "../assets/images/Gradient/manufacturing-engineering.png";
import manufacturingWhite from "../assets/images/White/manufacturing-engineering-white.png";
import reverse from "../assets/images/Gradient/reverse-engineering.png";
import reverseWhite from "../assets/images/White/reverse-engineering-white.png";
import migration from "../assets/images/Gradient/data-migration.png";
import migrationWhite from "../assets/images/White/data-migration-white.png";
import validation from "../assets/images/Gradient/data-validation-&-virtual-simulation.png";
import validationWhite from "../assets/images/White/data-validation-&-virtual-simulation-white.png";
import automation from "../assets/images/Gradient/automation.png";
import automationWhite from "../assets/images/White/automation-white.png";

//Thumbnail Images
import productThumb from "../assets/images/home/expertise-engineering/product-design.png";
import manufacturingThumb from "../assets/images/home/expertise-engineering/manufacturing-engineering.png";
import reverseThumb from "../assets/images/home/expertise-engineering/reverse-engineering-&-vave.png";
import migrationThumb from "../assets/images/home/expertise-engineering/data-migration-&-tech-publication.png";
import validationThumb from "../assets/images/home/expertise-engineering/design-validation-&-virtual-simulation.png";
import automationThumb from "../assets/images/home/expertise-engineering/automation.png";

export const EngineeringList = [
  {
    id: 1,
    title: "Product design",
    defaultIcon: prodDesign,
    activeIcon: prodDesignWhite,
    thumbnail: productThumb,
    content: [
      {
        keypoints: "Concept Development",
      },
      {
        keypoints: "Styling Feedback",
      },
      {
        keypoints: "Functional design review",
      },
      {
        keypoints: "Design Development",
      },
      {
        keypoints: "Interior Trims(BIW)",
      },
    ],
  },

  {
    id: 2,
    title: "Manufacturing Engineering",
    defaultIcon: manufacturing,
    activeIcon: manufacturingWhite,
    thumbnail: manufacturingThumb,
    content: [
      {
        keypoints: "Process Planning",
      },
      {
        keypoints: "Digital Manufacturing",
      },
      {
        keypoints: "Workstation Design",
      },
      {
        keypoints: "Tools and Equipment Design",
      },
      {
        keypoints: "Packaging Design",
      },
      {
        keypoints: "Computer Aided Manufacturing",
      },
      {
        keypoints: "Bench Marking",
      },
    ],
  },
  {
    id: 3,
    title: "Reverse Engineering & VAVE",
    defaultIcon: reverse,
    activeIcon: reverseWhite,
    thumbnail: reverseThumb,
    content: [
      {
        keypoints: "Ideation",
      },
      {
        keypoints: "Functional Analysis",
      },
      {
        keypoints: "Evaluation Selection",
      },
    ],
  },
  {
    id: 4,
    title: "Data Migration & Tech. Publication",
    defaultIcon: migration,
    activeIcon: migrationWhite,
    thumbnail: migrationThumb,
    content: [
      {
        keypoints: "2D to 3D Creation",
      },
      {
        keypoints: "CAD Platform Migration",
      },
      {
        keypoints: "Technical Documentation and User manuals",
      },
    ],
  },
  {
    id: 5,
    title: "Design Validation & Virtual Simulation",
    defaultIcon: validation,
    activeIcon: validationWhite,
    thumbnail: validationThumb,
    content: [
      {
        keypoints: "FE Molding",
      },
      {
        keypoints: "Strength & Durability",
      },
      {
        keypoints: "CFD",
      },
      {
        keypoints: "Mfg. Process Simulation",
      },
    ],
  },

  {
    id: 6,
    title: "Automation",
    defaultIcon: automation,
    activeIcon: automationWhite,
    thumbnail: automationThumb,
    content: [
      {
        keypoints: "Process Automation",
      },
      {
        keypoints: "Toolkit development",
      },
      {
        keypoints: "Design Automation",
      },
    ],
  },
];
