import React from "react";
import bannerImg from "../../assets/images/capabilities/capabilities-banner.png";
import EngineeringCapabilities from "./component/EngineeringCapabilities";
import Industries from "./component/Industries";
import ProductDesign from "./component/ProductDesign";
import { InnerBanner } from "../../components/InnerBanner";
import { Helmet } from "react-helmet";

const Capabilities = () => {
  return (
    <div className="">
      <>
        <Helmet>
          <title>{`Capabilities | Alpha De Serve`}</title>
        </Helmet>
        <InnerBanner
          title={<>Unleashing engineering potential</>}
          // subtitle={"Capabilities"}
          description={
            "Explore Alpha De Serve's advanced capabilities that drive innovation, efficiency, and excellence across industries."
          }
          banner_kv={bannerImg}
        />
        <EngineeringCapabilities />
        <ProductDesign />
        <Industries />
      </>
    </div>
  );
};

export default Capabilities;
