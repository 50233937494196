import React from "react";
import { industriescardList } from "../../../const/industrieslist";

const Industries = () => {
  return (
    <section className="industries-sec">
      <div className="container">
        <h2 data-aos="fade-up" data-aos-duration="2000">
          Industries
        </h2>
        <div className="row">
          <div className="col" data-aos="fade-up" data-aos-duration="1500">
            <ul>
              {industriescardList.map((industry) => (
                <li key={industry.id}>
                  <div className="industries-card">
                    <div className="industries-img">
                      <img
                        src={industry.thumbnail}
                        alt="Automotive"
                        className="img-Automotive"
                      />
                    </div>
                    <div className="industries-content">
                      <h4>{industry.tittle}</h4>
                      <p>{industry.para}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industries;
