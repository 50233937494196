import React from "react";
import { ProductList } from "../../../const/Products";
import Products from "../../../components/products";

const ProductDesign = () => {
  return (
    <section className="pt-0 product-sec">
      <div className="container">
        <div className="list ">
          <div className="products">
            <Products data={ProductList} customClass={" it-expertise"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDesign;
