import React, { useState } from "react";
import Slider from "react-slick";
import { cardData } from "../../../const/servicelist";
import SliderCard from "../../../components/cards/SliderCard";
import CustomPrevArrow from "../../../components/SliderPrevArrow";
import arrow from "../../../assets/images/arrow.png";

const ServiceInfoSlider = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <figure className="icon">
          <img src={arrow} alt="Arrow Prev" className="img-fluid mt-3" />
        </figure>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: true,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: true,
          infinite: true,
        },
      },
    ],
  };

  return (
    <section className="pb-0">
      <div className="container">
        <div
          className="service-section"
          data-aos="fade-up"
          data-aos-duration="2500"
          data-aos-delay="100"
        >
          <div className="slider">
            <Slider {...settings}>
              {cardData.map((card, index) => (
                <SliderCard
                  key={card.id}
                  number={card.number}
                  text={card.text}

                  // isActive={index === activeIndex}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceInfoSlider;
