export const aboutOurService = [
  {
    id: 1,
    count: "25+",
    description: "Successful Projects Delivered",
  },
  {
    id: 2,
    count: "10+",
    description: "Industries Served",
  },
  {
    id: 3,
    count: "90%",
    description: "Client Satisfaction Rate",
  },
  {
    id: 4,
    count: "20+",
    description: "Years of Combined Engineering Experience",
  }
];
