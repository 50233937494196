import React from "react";
import { servicecardContent } from "../../../const/servicelist";

const ServiceCard = () => {
  return (
    <section>
      <div className="container">
        <div
          className="engineering-section"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2500"
        >
          <div className="text-center">
            <h2 className="mb-5">Engineering services</h2>
          </div>
          <div>
            <ul className="service-card-grid">
              {servicecardContent.map((service) => (
                <li key={service.id} className="services-list">
                  <div className="service-card">
                    <div className="service-square-img">
                      <img src={service.image} alt="Service_img" />
                    </div>
                    <div className="service-content">
                      <h6 className="service-title">{service.title}</h6>
                      <p className="service-description">{service.para}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceCard;
