import React from "react";
import EngineeringCard from "./components/EngineeringCard";
import TalentServices from "./components/TalentServices";
import ServiceInfoSlider from "./components/ServiceInfoSlider";
import RolesList from "./components/RolesList";
import OnboardingProcess from "./components/OnboardingProcess";
import { InnerBanner } from "../../components/InnerBanner";
import bannerImg from "../../assets/images/Service/service-banner.png";
import { Helmet } from "react-helmet";
import AOS from "aos";

const Services = () => {
  AOS.init({
    easing: "ease-out-back",
    duration: "3000",
  });
  return (
    <>
      <Helmet>
        <title>{`Services | Alpha De Serve`}</title>
      </Helmet>
      <InnerBanner
        title={<>Comprehensive engineering & talent solutions</>}
        description={
          "Alpha De Serve provides industry-leading engineering services and talent solutions designed to elevate your business to new heights."
        }
        banner_kv={bannerImg}
      />

      <EngineeringCard />
      <TalentServices />
      <ServiceInfoSlider />
      <RolesList />
      {/* <OnboardingProcess /> */}
    </>
  );
};

export default Services;
