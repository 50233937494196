import React from "react";

const About = () => {
  return (
    <section className="work-sec">
      <div className="container ">
        <div className="row work-row">
          <div
            className="col-lg-4"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="50"
          >
            <h2>What we do</h2>
          </div>
          <div className="col-lg-8 work-content">
            <div
              className="work-points"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="100"
            >
              <h3>01</h3>
              <h3 className="work-title">Comprehensive Project Solutions</h3>
              From inception to completion, we offer full-spectrum project
              support with a team of skilled professionals dedicated to ensuring
              your success.
            </div>
            <div
              className="work-points"
              data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-delay="100"
            >
              <h3>02</h3>
              <h3 className="work-title">
                Cutting-Edge Engineering Software Solutions
              </h3>
              Leverage our domain-specific software tools to enhance
              productivity, streamline processes, and drive innovation in every
              project.
            </div>
            <div
              className="work-points"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="100"
            >
              <h3>03</h3>
              <h3 className="work-title">
                Tailored Talent Acquisition for contract and permanent roles
              </h3>
              We specialize in recruiting top-tier talent for both contract and
              permanent roles, precisely matching the right expertise to your
              unique needs.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
