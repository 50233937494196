import React from "react";
import logo from "../assets/images/logo.png";
import contectIcon from "../assets/images/contectIcon.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <img src={logo} alt="logo" className="img-fluid" />
          </div>
          <div className="col-lg-5">
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/services">Services</NavLink>
              </li>
              <li>
                <NavLink to="/capabilities">Capabilities</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <div className="contectInfo">
              <div>
                <a href="mailto:info@alphadeserve.com">
                  info@alphadeserve.com
                </a>
                <a href="tel:+91 99621 18160">
                  <h5 className="mb-0">+91 99621 18160</h5>
                </a>
              </div>
              <img src={contectIcon} alt="mail" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="copyRight">
        <div className="lineStyle"></div>
        <p>Copyright @ {year}. Alpha De Serve All rights reserved. </p>
      </div>
    </footer>
  );
};

export default Footer;
