import React from "react";
import RolesListImage from "../../../assets/images/Service/list-of-roles.png";
import { ListOfRoles } from "../../../const/servicelist";

const RolesList = () => {
  return (
    <section>
      <div className="container">
        <div className="role-section">
          <div
            className="row roles-content"
            data-aos="fade-up"
            data-aos-duration="2500"
          >
            <div className="col-lg-6">
              <figure className="role-list-image">
                <img
                  src={RolesListImage}
                  alt="RolesListImage"
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="col-lg-6 roles-part">
              <h3>List of roles</h3>
              <ul className="role-items">
                {ListOfRoles.map((roles, index) => (
                  <li key={index}>{roles.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RolesList;
