import React from "react";
import logoThumb from "../../../assets/images/logo-thumb.png";
import dot from "../../../assets/images/botts-img.png";
import { WhyWeAreBest } from "../../../const/whyWeareBest";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";

const WeAreBest = () => {
  const navigate = useNavigate();

  const goToCapabilitiesPage = () => {
    navigate("/capabilities");
  };

  return (
    <section className="p-0">
      <div className="container">
        <div className="row weare-best">
          <div
            className="col-lg-3"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2100"
            data-aos-delay="100"
          >
            <h2>
              Why we <br /> are best?
            </h2>
            <div>
              <img src={dot} alt="dot" />
            </div>
            <div className="mt-4">
              <PrimaryButton
                buttonName={"Our Capablities"}
                type={"button"}
                customClass="btn-white"
                onClick={goToCapabilitiesPage}
              />
            </div>
          </div>
          <div
            className="col-lg-8"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2100"
            data-aos-delay="100"
          >
            <ul className="best-list">
              {WhyWeAreBest?.map((data, index) => (
                <li key={index}>{data?.item}</li>
              ))}
            </ul>
          </div>
          {/* <div
            className="col-lg-3"
            data-aos="fade-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1700"
            data-aos-delay="50"
          >
            <figure className="m-0">
              <img src={logoThumb} alt="logoThumb" />
            </figure>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default WeAreBest;
