export const WhyWeAreBest = [
  {
    item: "Cost leverage through flexible business model",
  },
  {
    item: "Efficiencies in PD and NPI processes",
  },
  {
    item: "Access to talent",
  },
  {
    item: "Product localization and VAVE",
  },
  {
    item: "Lean and knowledge-based engineering improvements",
  },
  {
    item: "Global partnership support",
  },
  {
    item: "Optimized material consumption and reduced scrap",
  },
  {
    item: "Certified service provider",
  },
];
