import React from "react";

const PrimaryButton = ({ buttonName, type, customClass, onClick }) => {
  return (
    <div>
      <button
        type={type}
        className={`primary-btn app-button ${customClass}`}
        onClick={onClick}
      >
        {buttonName}
      </button>
    </div>
  );
};

export default PrimaryButton;
