import React from "react";
import aboutImg from "../../../assets/images/home/who-we-are.png";
import PrimaryButton from "../../../components/PrimaryButton";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const goToAboutPage = () => {
    navigate("/about-us");
  };

  return (
    <section className="about-sec">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-right"
            data-aos-duration="2300"
          >
            <figure className="m-0">
              <img src={aboutImg} alt="about" />
            </figure>
          </div>
          <div
            className="col-lg-6 about-content"
            data-aos="fade-left"
            data-aos-duration="2300"
          >
            <h2>Who we are</h2>
            <p>
              Founded in 2014, we are a team of passionate engineers and
              industry experts dedicated to providing top-tier engineering
              services. With decades of combined experience, we bring
              cutting-edge solutions that drive efficiency, safety, and
              innovation across various industries. Our commitment to excellence
              and client satisfaction has made us a trusted partner for
              companies looking to elevate their operations.
            </p>

            <div className="mt-1">
              <PrimaryButton
                buttonName={"Learn more"}
                type={"button"}
                onClick={goToAboutPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
