import cadModelDraftImg from "../assets/images/Service/cad-model-draft.png";
import wiringHarnessDesignImg from "../assets/images/Service/wiring-harness-design.png";
import hydraulicRoutingDesignImg from "../assets/images/Service/hydraulic-routing-design.png";
import interiorCompDesignImg from "../assets/images/Service/Interior-trims-comp-design.png";
import factoryLayoutDesignImg from "../assets/images/Service/factory-layout-design.png";
import simulationAnalysisImg from "../assets/images/Service/simulation-analysis.png";
import costingAssuranceImg from "../assets/images/Service/costing-assurance.png";
import reverseEngineerImg from "../assets/images/Service/reverse-engineer.png";
import needsAssessmentImg from "../assets/images/home/needs_assessment.png";
import sourceScreeningImg from "../assets/images/home/sourcing_screening.png";
import technicalEvalImg from "../assets/images/home/technical_evaluation.png";
import culturalImg from "../assets/images/home/cultural_fit_analysis.png";

export const servicecardContent = [
  {
    id: 1,
    title: "CAD Modeling and Drafting",
    para: "Transform ideas into detailed 3D models and precise technical drawings, optimizing your design process and reducing time to market.",
    image: cadModelDraftImg,
  },
  {
    id: 2,
    title: "Wiring Harness Design",
    para: "Design efficient and reliable wiring harnesses that meet industry standards, ensuring seamless integration across various systems.",
    image: wiringHarnessDesignImg,
  },
  {
    id: 3,
    title: "Hydraulic Routing Design",
    para: "Develop optimized hydraulic systems with accurate routing designs, ensuring robust performance and minimal energy loss.",
    image: hydraulicRoutingDesignImg,
  },
  {
    id: 4,
    title: "Interior Trims Components Design",
    para: "Create aesthetically pleasing and functional interior components with precision design, enhancing user experience and product appeal.",
    image: interiorCompDesignImg,
  },
  {
    id: 5,
    title: "Factory Layout Design",
    para: "Design intelligent factory layouts that maximize space utilization, streamline operations, and boost productivity.",
    image: factoryLayoutDesignImg,
  },
  {
    id: 6,
    title: "Simulation and Analysis",
    para: "Utilize advanced simulation and analysis tools to validate designs, predict performance, and mitigate risks before production.",
    image: simulationAnalysisImg,
  },
  {
    id: 7,
    title: "Reverse Engineering",
    para: "Deconstruct and analyze existing products to improve designs, enhance functionality, and reduce production costs.",
    image: reverseEngineerImg,
  },
  {
    id: 8,
    title: "Coasting Assurance",
    para: "Provide detailed costing analysis to ensure project feasibility, budget alignment, and optimal resource allocation.",
    image: costingAssuranceImg,
  },
];

export const included_services = [
  {
    title: "Contract Staffing",
    text: "Access highly skilled professionals for short-term projects, ensuring you have the right talent exactly when you need it.",
  },
  {
    title: "Permanent Recruitment",
    text: "Find the perfect candidates for long-term roles, carefully selected to match your company culture and technical requirements.",
  },
  {
    title: "Project-Based Staffing",
    text: "Build a team of experts for specific projects, with the flexibility to scale as your needs evolve",
  },
];

export const cardData = [
  { id: 1, number: "100+", text: "Engineers and Specialists in Our Network" },
  { id: 2, number: "25+", text: "Successful Projects Completed" },
  {
    id: 3,
    number: "80%",
    text: "Client Retention Rate",
  },
  { id: 4, number: "10+", text: "Industries Served Globally" },
];

export const ListOfRoles = [
  {
    text: "Sheet metal,Casting & Plastics Design Engineers with Auto-CAD,MicroStation, Creo,CATIA, Solid Works & NX(UG) Experience engineers",
  },
  { text: "Reverse Engineers" },
  { text: "Wiring Harness(Electrical) Engineers" },
  { text: "Hydraulic Engineers(Fluid lines)" },
  { text: "Packaging Design Engineers" },
  { text: "Interior Trims components Design" },
  { text: "Costing Engineers" },
  { text: "Simulation Engineers" },
];

export const OnboardingCard = [
  {
    id: 1,
    number: "01",
    title: "Needs Assessment",
    // text: 'We work closely with you to understand your project requirements and identify the key skills needed.',
    image: needsAssessmentImg,
  },
  {
    id: 2,
    number: "02",
    title: "Sourcing & Screening",
    // text: 'Our recruitment specialists leverage extensive networks and advanced screening tools to find the best candidates.',
    image: sourceScreeningImg,
  },
  {
    id: 3,
    number: "03",
    title: "Technical Evaluation",
    // text: 'Candidates undergo a thorough technical assessment to ensure they possess the necessary expertise and problem-solving capabilities.',
    image: technicalEvalImg,
  },
  {
    id: 4,
    number: "04",
    title: "Cultural Fit Analysis",
    // text: 'We evaluate candidates for alignment with your company’s culture and values, ensuring a seamless integration into your team.',
    image: culturalImg,
  },
  // { id: 5,number:"05", title: "Final Selection & Onboarding",
  //   //  text: 'We manage the entire hiring process, from interviews to onboarding, ensuring a smooth transition for your new hires.',
  //    image: onboardingImage1 },
  // {
  //   id: 5,
  //   number: "05",
  //   title: "Needs Assessment",
  //   //  text: 'We work closely with you to understand your project requirements and identify the key skills needed',
  //   image: needsAssessmentImg,
  // },
  // {
  //   id: 6,
  //   number: "06",
  //   title: "Sourcing & Screening",
  //   // text: 'Our recruitment specialists leverage extensive networks and advanced screening tools to find the best candidates.',
  //   image: technicalEvalImg,
  // },
  // {
  //   id: 7,
  //   number: "07",
  //   title: "Technical Evaluation",
  //   // text: 'Candidates undergo a thorough technical assessment to ensure they possess the necessary expertise and problem-solving capabilities.',
  //   image: technicalEvalImg,
  // },
  // {
  //   id: 8,
  //   number: "08",
  //   title: "Cultural Fit Analysis",
  //   // text: 'We evaluate candidates for alignment with your company’s culture and values, ensuring a seamless integration into your team.',
  //   image: culturalImg,
  // },
];
