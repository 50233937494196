import React from "react";
import detailsImg from "../../../assets/images/aboutUs/about-us.png";

export const DetailAbout = () => {
  return (
    <section className="about-sec">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 text-center"
            data-aos="fade-right"
            data-aos-duration="2300"
          >
            <figure className="m-0">
              <img src={detailsImg} alt="about" />
            </figure>
          </div>
          <div
            className="col-lg-6 about-content"
            data-aos="fade-left"
            data-aos-duration="2300"
          >
            <h2>Who we are</h2>
            <p>
              We are more than just an engineering consultancy; we are a partner
              in your success. Founded in 2014 on the principles of innovation,
              integrity, and excellence, we bring together a team of highly
              skilled professionals dedicated to delivering tailored engineering
              solutions across diverse industries. Our mission is to empower
              businesses by providing them with the tools, expertise, and
              insights needed to excel in today’s fast-paced, ever-evolving
              world.
            </p>
            <p>
              We believe that every challenge presents an opportunity to
              innovate. By combining our technical expertise with a deep
              understanding of our clients’ needs, we deliver solutions that are
              not only effective but also sustainable and future-ready.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
