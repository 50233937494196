import React, { useState, useEffect } from "react";

const Expertise = ({ customClass, data }) => {
  const [activeItem, setActiveItem] = useState(data[0]);

  const handleClick = (item) => {
    setActiveItem(item);
  };

  useEffect(() => {
    if (data.length > 0) {
      setActiveItem(data[0]);
    }
  }, [data]);

  return (
    <div className="expertise-group">
      <div className={`row ${customClass}`}>
        <div className="col-lg-6">
          <div className="expertise-list">
            <ul>
              {data.map((item) => (
                <li
                  key={item.id}
                  onClick={() => handleClick(item)}
                  className={`expertise-item ${
                    activeItem?.id === item.id ? "active-card" : ""
                  }`}
                >
                  {item?.defaultIcon || item?.activeIcon ? (
                    <div>
                      <img
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        src={
                          activeItem?.id === item.id
                            ? item?.activeIcon
                            : item?.defaultIcon
                        }
                        alt=""
                        className=""
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <p data-aos="fade-in">{item?.title}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-lg-6 experties-bg">
          <div className="experties-content pt-0">
            <div
              className="text-center"
              data-aos="zoom-out"
              data-aos-duration="2000"
              data-aos-delay="100"
            >
              {activeItem && <img src={activeItem.thumbnail} alt="Thumbnail" />}
            </div>
            <div>
              <ul
                data-aos="fade-up"
                data-aos-duration="2500"
                data-aos-delay="100"
              >
                {activeItem?.content?.map((selectItem, index) => (
                  <li key={index}>{selectItem?.keypoints}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expertise;
