import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import appLogo from "../assets/images/logo.png";

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const sidebarClose = () => {
    setIsActive(false);
  };

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    if (header) {
      const scrollTop = window.scrollY;
      scrollTop >= 50
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  return (
    <header>
      <div className={`header-section ${isActive ? "nav-open" : ""}`}>
        <div id="header">
          <div className="sm-logo">
            <NavLink>
              <img src={appLogo} alt="AppLogo" />
            </NavLink>
          </div>
          <div>
            <button className="nav-toggle" onClick={handleClick}>
              <div className="hamburger">
                <span></span>
              </div>
            </button>
          </div>
          <nav className="nav" id="navbar">
            <div className="logo">
              <NavLink to="/">
                <img src={appLogo} alt="AppLogo" />
              </NavLink>
            </div>
            <ul className="nav_list" id="navlinkitems">
              <li className="nav_item"></li>
              <li className="nav_item" onClick={sidebarClose}>
                <NavLink to={"/"} className={"nav_link"}>
                  Home
                </NavLink>
              </li>
              <li className="nav_item" onClick={sidebarClose}>
                <NavLink to={"/about-us"} className={"nav_link"}>
                  About Us
                </NavLink>
              </li>
              <li className="nav_item" onClick={sidebarClose}>
                <NavLink to={"/services"} className={"nav_link"}>
                  Services
                </NavLink>
              </li>
              <li className="nav_item" onClick={sidebarClose}>
                <NavLink to={"/capabilities"} className={"nav_link"}>
                  Capabilities
                </NavLink>
              </li>
              <li className="nav_item last-child" onClick={sidebarClose}>
                <NavLink to={"/contact-us"} className="contact-btn app-button">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
export default Header;
