import React, { useEffect, useState } from "react";
import arrow from "../../../assets/images/arrow.png";
import Slider from "react-slick";
import CustomPrevArrow from "../../../components/SliderPrevArrow";
import { aboutOurService } from "../../../const/aboutOurService";

export const OurService = () => {
  const data = aboutOurService;
  const [activeItem, setActiveItem] = useState(data[1]);
  const handleClick = (item) => {
    setActiveItem(item);
  };
  useEffect(() => {
    if (data.length > 0) {
      setActiveItem(data[0]);
    }
  }, [data]);
  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <figure className="icon">
          <img src={arrow} alt="Arrow Prev" className="img-fluid" />
        </figure>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          autoplay: true,
          dots: true,
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <section className="ourserviceList">
      <div className="container">
        <div
          className="slider"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2300"
          data-aos-delay="100"
        >
          <div className="row">
            <Slider {...settings}>
              {aboutOurService?.map((item) => {
                return (
                  // <div className="col-lg-3">
                  <div className="">
                    <div
                      className={`serviceSlider ${
                        activeItem?.id === item.id ? "active-card" : ""
                      }`}
                      onClick={() => handleClick(item)}
                    >
                      <h2>{item.count}</h2>
                      <p>{item.description}</p>
                    </div>
                  </div>
                  // </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
