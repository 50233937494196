import React from "react";
import ConnectWithUs from "./component/ConnectWithUs";
import ClientList from "./component/ClientList";
import Banner from "./component/Banner";
import About from "./component/About";
import Work from "./component/Work";
import ExpertiseEngineering from "./component/ExpertiseEngineering";
import WeAreBest from "./component/WeAreBest";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div className="">
      <Helmet>
        <title>{`Alpha De Serve`}</title>
      </Helmet>
      <Banner />
      <About />
      <Work />
      <ExpertiseEngineering />
      {/* <ExpertiseIt /> */}
      <WeAreBest />
      {/* <OurTalent /> */}
      <ClientList />
      <ConnectWithUs />
    </div>
  );
};

export default Home;
