import React from "react";
import techExpert from "../../../assets/images/techexpert.png";
import borderImg from "../../../assets/images/border-img.png";

const EngineeringCapabilities = () => {
  return (
    <section className="eng-capability">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-8 content"
            data-aos="fade-right"
            data-aos-duration="2300"
          >
            <h2>Engineering capabilities</h2>
            <p>
              Alpha De Serve stands at the forefront of engineering excellence,
              delivering solutions that are both innovative and impactful. Our
              comprehensive capabilities empower businesses to overcome
              challenges, accelerate development, and achieve sustainable
              growth. From concept to completion, our expertise ensures that
              your projects are realized with precision and efficiency.
            </p>
          </div>
          <div
            className="col-lg-4 engineering-card "
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <div className="engineering-icon expertise-item active-card">
              <img src={techExpert} alt="techExpert" />
            </div>
            <div className="engineering-content">
              <h3 className="m-0">Technical expertise</h3>
              {/* <p>Lorem Ipsum dolor sit amet consectur</p> */}
            </div>
          </div>
        </div>
        <div className="border-img">
          <img src={borderImg} alt="border-img" />
        </div>
      </div>
    </section>
  );
};

export default EngineeringCapabilities;
